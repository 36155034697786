@use 'sass:math';

@import "~evostrap/lib/css/core/functions";
@import "~evostrap/lib/css/core/mixins/breakpoints";
@import "~evostrap/lib/css/core/variables";

// ICONS
$icomoon-font-path: "../../assets/doceo-icons/fonts" !default;
@import "../../assets/doceo-icons/style.scss";

.doceo-icon.doceo-icon-2x {
    font-size: 200%;
}

.container-fluid {
    padding-left: 20px;
    padding-right: 20px;
}

// Personnalisation accordion - Prise en charge extra tools
.es_accordion {
    .es_accordion-item {
        .es_accordion-item-title.collapsed {
            .es_accordion-item-title-tools {
                display: none !important;
            }
        }
    }
}


// RING
.doceo-ring {

    @include media-breakpoint-between("lg", "xxl") {
        .doceo-ring-steps {
            display: flex;
            border: 1px solid $card-border-color;
            overflow: auto;


            .doceo-ring-steps-step {
                background: $light;
                flex: 1 0 auto;
                position: relative;
                display: flex;
                justify-content: space-between;

                &:after {
                    background: url('./../../assets/img/triangle.svg');
                    content: "";
                    width: 32px;
                    background-size: 100% 100%;
                }


                &:last-child {
                    &:after {
                        content: none;
                    }
                }

                +.doceo-ring-steps-step {
                    border-left: none;

                }

                .doceo-ring-steps-step-content {
                    padding: $btn-padding-y $btn-padding-x;
                    display: flex;
                    flex-direction: column;

                    @include media-breakpoint-up('xl') {
                        flex-direction: row;
                    }

                    .doceo-ring-steps-step-label {
                        margin-right: $spacer * .25;
                    }
                }

            }
        }
    }


    @include media-breakpoint-down("md") {
        .doceo-ring-steps {
            border: 1px solid $card-border-color;
            padding: $card-spacer-y $card-spacer-x;
            background: $light;



            .doceo-ring-steps-step {

                &:first-child {
                    .doceo-ring-steps-step-content .doceo-ring-steps-step-label::before {
                        content: none;
                    }
                }

                &:last-child {
                    .doceo-ring-steps-step-content .doceo-ring-steps-step-label::after {
                        content: none;
                    }
                }

                .doceo-ring-steps-step-content {
                    display: flex;

                    .doceo-ring-steps-step-label {
                        padding: $btn-padding-y $btn-padding-x;
                        width: 50%;
                        position: relative;
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-start;
                        text-align: right;

                        // Bordure de progression
                        &::before {
                            content: "";
                            width: 1px;
                            height: 12px;
                            background: $border-color;
                            right: -1px;
                            position: absolute;
                            top: 0;
                        }

                        &:after {
                            content: "";
                            width: 1px;
                            height: calc(100% - 12px);
                            background: $border-color;
                            right: -1px;
                            position: absolute;
                            bottom: 0;
                        }
                    }

                    .doceo-ring-steps-step-details {
                        padding: $btn-padding-y $btn-padding-x;
                        width: 50%;
                        position: relative;
                        display: flex;
                        align-items: center;
                        word-break: break-all;

                        // Chips
                        &:before {
                            display: block;
                            content: "";
                            width: 9px;
                            background-color: $border-color;
                            height: 9px;
                            position: absolute;
                            border-radius: 50%;
                            left: - math.div(9, 2) + px;
                            top: 12px
                        }
                    }
                }


                &.active {
                    >.doceo-ring-steps-step-content {

                        .doceo-ring-steps-step-label {


                            &::before {

                                background: $primary;

                            }
                        }

                        .doceo-ring-steps-step-details {

                            // Chips
                            &:before {
                                background-color: $primary;

                            }
                        }

                    }

                    &.next-is-active {
                        >.doceo-ring-steps-step-content {

                            .doceo-ring-steps-step-label {


                                &:after {

                                    background: $primary;

                                }
                            }
                        }
                    }

                }

            }
        }
    }

    @include media-breakpoint-down('xs') {
        .doceo-ring-steps {

            .doceo-ring-steps-step {

                .doceo-ring-steps-step-content {
                    flex-direction: column;
                    position: relative;
                    padding: $btn-padding-y $btn-padding-x;

                    .doceo-ring-steps-step-label {
                        width: 100%;
                        position: initial;
                        justify-content: flex-start;
                        text-align: left;
                        padding: 0;


                        // Bordure de progression
                        &::before {
                            left: 0;
                            right: initial;
                        }

                        &:after {
                            left: 0;
                            right: initial;
                        }
                    }

                    .doceo-ring-steps-step-details {
                        width: 100%;
                        position: initial;
                        padding: 0;


                        // Chips
                        &:before {
                            top: 12px;
                        }
                    }
                }




            }
        }
    }



}

.doceo_cre-interventions {
    .doceo_cre-intervention-item {
        .doceo_cre-intervention-item-picture {

            a {
                overflow: hidden;

                .card-body {
                    position: relative;

                    .doceo_cre-intervention-item-picture-hover {
                        opacity: 0;
                        transition: .25s;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        content: "";
                        background: rgba(0, 0, 0, .8);
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                    }
                }


                &:hover {
                    .doceo_cre-intervention-item-picture-hover {

                        opacity: 1;

                        .doceo-icon-camera {
                            transition: .25s;
                            transform: scale(4);
                            color: $gray-500
                        }

                    }

                }


            }
        }
    }
}

// doceo-oj-index
.doceo-oj-index {

    .doceo-icon-clock {
        color: $text-muted;
    }


    .doceo-oj-index-header {

        padding: $card-spacer-y $card-spacer-x;
        border-top: $card-border-width solid $card-border-color;
        border-left: $card-border-width solid $card-border-color;
        border-right: $card-border-width solid $card-border-color;
        border-top-left-radius: $card-border-radius;
        border-top-right-radius: $card-border-radius;
    }

    .doceo-oj-index-body {}
}


@media (min-width: map-get($map: $grid-breakpoints, $key: lg)) {

    table.doceo-table-analytic {
        tbody td:first-child {
            width: 60%;
        }
    }


}


.doceo-download {
    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            position: relative;

            a {
                padding: .625rem .75rem;
                display: block;
                position: relative;

                &:focus {
                    color: $primary;
                    background-color: $gray-100;
                    box-shadow: none !important;
                }

            }


            &:not(:last-child) {
                &:after {
                    height: 1px;
                    background-color: $border-color;
                    position: absolute;
                    bottom: 0;
                    left: .625rem;
                    right: .625rem;
                    content: ""
                }
            }
        }
    }
}



/* pour forcer les données typographiques */
.doceo-font-family-base {
    font-family: $font-family-sans-serif !important;
}

.doceo-font-size-base {
    font-size: $font-size-base !important;
}

.doceo-font-size-lg {
    font-size: $font-size-lg !important;
}

.doceo-font-size-sm {
    font-size: $font-size-sm !important;
}

.doceo-font-size-xs {
    font-size: $font-size-xs !important;
}

.doceo-line-height-base {
    line-height: $line-height-base !important;
}

.doceo-font-weight-base {
    font-weight: $font-weight-base !important;
}

/* pour forcer le writing-mode horizontal */
.doceo-writing-mode-horizontal {
    writing-mode: horizontal-tb !important;
}

/* bloc telechargement des TC par parties */
.doceo-multipart-menu-item-label {
    padding: .625rem .75rem;
    display: block;
    position: relative;
}

/* sous-titres du CAPTION */
.doceo-caption {
    caption-side: top;
    text-align: center;
}