@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?2u43dl');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?2u43dl#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?2u43dl') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?2u43dl') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?2u43dl##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.doceo-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.doceo-icon-circle {
  &:before {
    content: $doceo-icon-circle; 
  }
}
.doceo-icon-triangle {
  &:before {
    content: $doceo-icon-triangle; 
  }
}
.doceo-icon-chevron {
  &:before {
    content: $doceo-icon-chevron; 
  }
}
.doceo-icon-hourglass {
  &:before {
    content: $doceo-icon-hourglass; 
  }
}
.doceo-icon-clock {
  &:before {
    content: $doceo-icon-clock; 
  }
}
.doceo-icon-camera {
  &:before {
    content: $doceo-icon-camera; 
  }
}
.doceo-icon-vote {
  &:before {
    content: $doceo-icon-vote; 
  }
}
.doceo-icon-vote-box {
  &:before {
    content: $doceo-icon-vote-box; 
  }
}

