$icomoon-font-family: "doceo" !default;
$icomoon-font-path: "fonts" !default;

$doceo-icon-circle: "\f111";
$doceo-icon-triangle: "\e901";
$doceo-icon-chevron: "\e902";
$doceo-icon-hourglass: "\e903";
$doceo-icon-clock: "\e904";
$doceo-icon-camera: "\e905";
$doceo-icon-vote: "\e906";
$doceo-icon-vote-box: "\e900";

